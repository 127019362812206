.input-section {
  margin-bottom: 24px;
}

.winner-select {
  margin-left: 8px;
  margin-bottom: 12px;
}

.MatchesEditor-rounds {
  display: flex;
  gap: 16px;
}
.brackets-viewer {
  padding: 0;
  --primary-background: none;
  --font-color: #fff;
  /* --round-margin: 24px; */
  --match-width: 138px;
  --match-vertical-padding: 0;
  font-family: 'Rexliafree', sans-serif;
}
.brackets-viewer h1 {
  display: none;
}
.brackets-viewer h3 {
  display: none;
}
.brackets-viewer .participant .name {
  display: flex;
}
.brackets-viewer .opponents > span:nth-of-type(1) {
  display: none;
}
.brackets-viewer .opponents > span:nth-of-type(2) {
  display: none;
}
.brackets-viewer .opponents > span:nth-of-type(3) {
  display: none;
}
.brackets-viewer .opponents > span:nth-of-type(4) {
  display: none;
}
.brackets-viewer .opponents > span:nth-of-type(5) {
  display: none;
}
.brackets-viewer .participant .name > span {
  display: none;
}
.brackets-viewer .match {
  margin: 7px 0;
}
.brackets-viewer .participant.win .name {
  color: var(--win-color);
  width: 91px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.brackets-viewer .participant.loss .name {
  color: var(--loss-color);
  width: 91px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
